// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-tsx": () => import("/drone/src/src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-education-page-tsx": () => import("/drone/src/src/templates/education-page.tsx" /* webpackChunkName: "component---src-templates-education-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("/drone/src/src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-info-page-tsx": () => import("/drone/src/src/templates/info-page.tsx" /* webpackChunkName: "component---src-templates-info-page-tsx" */),
  "component---src-templates-products-page-tsx": () => import("/drone/src/src/templates/products-page.tsx" /* webpackChunkName: "component---src-templates-products-page-tsx" */),
  "component---src-templates-generic-page-tsx": () => import("/drone/src/src/templates/generic-page.tsx" /* webpackChunkName: "component---src-templates-generic-page-tsx" */),
  "component---src-templates-faq-page-tsx": () => import("/drone/src/src/templates/faq-page.tsx" /* webpackChunkName: "component---src-templates-faq-page-tsx" */),
  "component---src-pages-404-tsx": () => import("/drone/src/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/drone/src/.cache/data.json")

